import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import useCluster from "./hooks/useCluster";

import Loading from "../../components/Loading/Loading";

import ChartSlider from "../../components/ChartSlider/ChartSlider";
import Slider from "../../components/Slider/Slider";
import SearchVolMonth from "./Visualizations/SearchVolMonth";
import SearchVolBubble from "./Visualizations/SearchVolBubble";
import PaidSERP from "./Visualizations/PaidSERP";
import ClusterSERPFeatures from "./Visualizations/ClusterSERPFeatures";
import ClusterTable from "./Visualizations/ClusterTable";
import ClusterOverview from "./ClusterOverview/ClusterOverview";
import ClusterLightbox from "./ClusterLightbox/ClusterLightbox";

import QuestionCard, { groupQuestions } from "./QuestionCard/QuestionCard";

import {
  formatCurrency,
  formatFloat,
  formatPercent,
} from "../../utils/tableUtils";

import "./Cluster.css";

import fallback from "../../assets/img/fallback.webp";

function Cluster() {
  const location = useLocation();
  const history = location.state?.history || [];
  const { clusterId, profileId } = useParams();
  const { cluster, loading, error } = useCluster(clusterId, profileId);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  console.log(cluster);

  // set element with class App to overflow hidden when lightbox is open
  useEffect(() => {
    const appElement = document.querySelector(".App");
    const htmlElement = document.querySelector("html");
  
    if (appElement && htmlElement) {
      if (lightboxOpen) {
        appElement.style.overflow = "hidden";
        htmlElement.style.overflow = "hidden";
      } else {
        appElement.style.overflow = "auto";
        htmlElement.style.overflow = "auto";
      }
    }
  }, [lightboxOpen]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">Error fetching data</h1>
            <p className="text-red-500 font-mono">
              {error?.message || "Something went wrong."}
            </p>
          </div>
        </section>
      </div>
    );
  }

  if (cluster == null || Object.keys(cluster).length === 0) {
    return (
      <div className="page-base space-y-4 md:space-y-8">
        <section className="page-section p-4 grid place-items-center text-center min-h-[450px]">
          <div className="space-y-4">
            <h1 className="text-2xl">No data available</h1>
            <p className="font-mono">
              No cluster data available for this profile.
            </p>
          </div>
        </section>
      </div>
    );
  }

  const groupedQuestions = groupQuestions(cluster.cluster_data?.popular_questions, 2);

  return (
    <>
      <Helmet>
        <title>{cluster?.cluster_data?.idea?.title || "Idea"} | Genti</title>
      </Helmet>

      <div className="cluster page-base space-y-4 md:space-y-8">
        
        <section className="page-section">
          <div className="section-header">
            <h1 className="">{ cluster?.cluster_data?.title }</h1>
            <div className="flex gap-2 px-2 ml-2 mt-3 border-l border-watermelon-500">
              <p>{ cluster?.cluster_data?.summary }</p>
            </div>
          </div>
        </section>

        {/* idea */}
        <div>
          <section className="page-section pb-2">
            <div className="section-header flex flex-col md:flex-row justify-between items-center gap-4">
              <div>
                <h2>{cluster?.cluster_data?.idea?.title || 'Idea'}</h2>
               { cluster?.cluster_data?.idea?.idea && <p className="ml-2 px-2 border-l border-watermelon-500 mt-3">{cluster?.cluster_data?.idea?.idea}</p>}
              </div>
            </div>
            <div className="cluster-overview">
              <img
                src={cluster?.cluster_data?.cluster_img || fallback}
                alt=""
                className="cluster-img"
                onClick={() => setLightboxOpen(true)}
              />
              {/* <div className="bg-transparent dark:bg-deep-space col-start-2">
                <div className="flex gap-4 items-center">
                  <h4 className="font-bold text-2xl">Action Plan</h4>
                  <button
                    onClick={() => setLightboxOpen(true)}
                    className="plan-btn">
                    <FontAwesomeIcon icon={faLightbulb} />
                    <span className="opacity-0">View full plan</span>
                  </button>
                </div>
                <ul className="space-y-4 p-2 lg:py-4 lg:px-8 text-xl">
                  { cluster?.cluster_data?.idea?.plan?.map((planItem, planIndex) => (
                      <li key={planIndex} className="flex items-center gap-x-2">
                        <FontAwesomeIcon icon={faCheck} />
                        {planItem.title}
                      </li>
                  ))}
                </ul>
              </div> */}

              <div className="col-start-1 row-start-2 col-span-full lg:col-start-2 lg:row-start-1">
                <ClusterOverview cluster={cluster} />
              </div>
            </div>
          </section>
          {/* <section className="-mb-4 -translate-y-4">
            <div className="col-start-1 row-start-2 col-span-2">
              <ClusterOverview cluster={cluster} />
            </div>
          </section> */}
        </div>

        {/* intent */}
        <section className="page-section">
          <div className="section-header">
            <h1 className="">Intent</h1>
          </div>

          <div className="cluster-summar p-4 space-y-4">

            <ol className="grid md:grid-cols-2 gap-x-4 md:gap-x-24 gap-y-4 md:gap-y-6 w-full">
              {cluster.cluster_data?.search_motivations?.map(
                (reason, index) => (
                  <li key={index}>
                    <h3 className="font-bold text-lg">{reason.title}</h3>
                    <p className="">{reason.description}</p>
                  </li>
                )
              )}
            </ol>
          </div>
        </section>

        {/* most popular questions */}
        <section className="page-section">
          <h2 className="section-header">Most Popular Questions</h2>

          { cluster.cluster_data?.popular_questions ? <Slider>
            { groupedQuestions.map((questionPair, slideIndex) => (
              <div className="slide flex flex-col gap-4 p-4" key={slideIndex}>
                {questionPair.map((question, index) => (
                  <div key={index} className="flex-1">
                    <QuestionCard
                      question={question}
                      index={slideIndex * 2 + index}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Slider>
          : <p className="p-4 text-center">No popular questions available for this cluster.</p>
          }
        </section>

        {/* keywords */}
        <section className="page-section">
          <div>
            <h2 className="section-header">Keywords</h2>
            <ChartSlider>
              <SearchVolMonth
                data={cluster.cluster_data?.total_search_volume_by_month}
              />
              <SearchVolBubble
                data={
                  cluster.cluster_data?.visualizations?.search_volume_by_type
                }
              />
              <PaidSERP
                data={cluster.cluster_data?.visualizations?.paid_serp}
              />
              <ClusterSERPFeatures
                data={cluster.cluster_data?.visualizations?.serp_features}
              />
            </ChartSlider>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="info-boxes grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                <h2 className="font-bold text-center">Share</h2>
                <div className="text-2xl font-semibold flex justify-between gap-4">
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">  { formatPercent(cluster?.cluster_data?.visualizations?.cluster_share?.[1]?.y ?? 0, 2) }</div>
                  <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_keyword_count, 0) }/kws</div>
                </div>
              </div>
              </div>

            <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
              <div className="space-y-4">
                  <h2 className="font-bold text-center">
                    Total Volume (mo/yr)
                  </h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.total_search_volume, 0) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatFloat(cluster?.cluster_data?.annual_search_volume, 0) }</div>
                  </div>
                </div>
              </div>


              <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
                <div className="space-y-4">
                  <h2 className="font-bold text-center">Difficulty (avg/high)</h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.avg_diff, 0) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatPercent(cluster?.cluster_data?.most_difficult_diff?.max_kwd_value, 0) }</div>
                  </div>
                </div>
              </div>

              <div className="w-full p-4 space-y-4 rounded bg-[#0561B7] bg-opacity-10 border border-[#0561B7] border-opacity-20">
                <div className="space-y-4">
                  <h2 className="font-bold text-center">CPC (avg/high)</h2>
                  <div className="text-2xl font-semibold flex justify-between gap-4">
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.avg_cpc) }</div>
                    <div className="w-full py-2 rounded-sm text-center bg-[#0561B7] bg-opacity-20">{ formatCurrency(cluster?.cluster_data?.most_expensive_cpc?.max_cpc_value, 2) }</div>
                  </div>
                </div>
              </div>
          </div>

          <hr className="border-[#0561B7] border-opacity-50 border-dotted" />

          <div className="overflow-x-auto">
            <ClusterTable
              data={cluster.cluster_data?.visualizations?.keywords}
              profileId={profileId}
              history={history}
            />
          </div>
        </section>
      </div>

      { lightboxOpen && (
        <ClusterLightbox
          cluster={cluster}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </>
  );
}

export default Cluster;

