/**
 * Groups an array of items into subarrays of a specified size.
 *
 * @param {Array} array - The original array to group.
 * @param {number} size - The number of items per group.
 * @returns {Array} - An array of grouped subarrays.
 */
export const groupQuestions = (array, size) => {
  // return if the array is empty or not an array
  if (!Array.isArray(array) || !array.length) return [];

  const groups = [];
  for (let i = 0; i < array.length; i += size) {
    groups.push(array.slice(i, i + size));
  }
  return groups;
};

const QuestionCard = ({ question, index }) => {
  return (
    <div className="
      relative 
      rounded
      overflow-hidden
      border
      border-[#0561B7]
      border-dotted
      border-opacity-[0.75]
    "
    >
      <div className="section-header p-2 leading-[1.0]">
        <h3>
          <span className="text-xl font-bold">{question.question}</span>
        </h3>
        <p className="ml-4">
          <span className="text-base font-normal">{question.description}</span>
        </p>
      </div>

      {/* Body of the card */}
      <div className="p-4">
        <ul className="space-y-3">
          <li className="flex items-center gap-4">
            <h4 className="font-bold min-w-[100px]"><span className="px-2 py-1 bg-[#F5F5F5] dark:bg-[#0561B7] dark:bg-opacity-10 rounded border border-dotted border-[#0561b7] dark:border-watermelon-500">Gap:</span></h4>
            <p>{question.gap_analysis}</p>
          </li>
          <li className="flex items-center gap-4">
            <h4 className="font-bold min-w-[100px]"><span className="px-2 py-1 bg-[#F5F5F5] dark:bg-[#0561B7] dark:bg-opacity-10 rounded border border-dotted border-[#0561b7] dark:border-watermelon-500">Idea:</span></h4>
            <p>{question.idea}</p>
          </li>
          <li className="flex items-center gap-4">
            <h4 className="font-bold min-w-[100px]"><span className="px-2 py-1 bg-[#F5F5F5] dark:bg-[#0561B7] dark:bg-opacity-10 rounded border border-dotted border-[#0561b7] dark:border-watermelon-500">Outcome:</span></h4>
            <p>{question.outcome}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};


export default QuestionCard;