import { useState } from "react";
import { Link } from "react-router-dom";

// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";


import './ConditionsList.css';

function ConditionsList({ conditions }) {
  const [showAll, setShowAll] = useState(false);

  if (!conditions || conditions.length === 0) return null;

  // If "showAll" is false, slice the array; otherwise show the full array
  const displayedConditions = showAll ? conditions : conditions.slice(0, 9);

  return (
    <div className="page-section mb-8">
      <h2 className="section-header flex items-end justify-between">
        Conditions

        {conditions.length > 9 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="btn conditions-list__toggle-button"
          >
            <span>{showAll ? "Show Less" : "Show All"}</span>
            <FontAwesomeIcon icon={showAll ? faChevronUp : faChevronDown} />
          </button>
        )}
      </h2>
      <ul className="conditions-list">
        {displayedConditions.map((condition, index) => (
          <li key={index} className="conditions-list__item">
            <Link className="condition-list__item__link" to={`/clusters/${condition.condition_uuid}`}>
              <img
                className="h-16 w-16 rounded"
                src={condition.condition_analysis_image}
                alt={condition.condition_name}
              />
              <h3 className="conditions-list__item__name">{condition.condition_name}</h3>
            </Link>
          </li>
        ))}
      </ul>

    </div>
  );
}

export default ConditionsList;
