import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCheck } from "@fortawesome/free-solid-svg-icons";

import youtube from "../../../assets/img/youtube.png";
import fallback from "../../../assets/img/fallback.webp";
import "./ClusterLightbox.css";

function ClusterLightbox({ cluster, onClose }) {
  const ideaData = cluster?.cluster_data?.idea || {};

  useEffect(() => {
    function handleEsc(e) {
      if (e.key === "Escape") {
        onClose();
      }
    }

    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  // if idea is empty or null
  if (Object.keys(ideaData).length === 0 || !ideaData) {
    return (
      <div className="lightbox-overlay">
        <button className="lightbox-close-btn" onClick={onClose}>
          <FontAwesomeIcon icon={faClose} />
        </button>
        <div className="lightbox-content content-center text-center font-bold">
          <div className="space-y-4">
            <p>Idea is currently unavailable</p>
            <button className="border border-deep-space dark:border-white px-16 py-3 hover:bg-white hover:bg-opacity-10" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="lightbox-overlay">
      <button className="lightbox-close-btn" onClick={onClose}>
        <FontAwesomeIcon icon={faClose} />
      </button>

      <div className="lightbox-content">
        <section className="page-section">
          <div className="section-header">
            <h2 className="">{ideaData.title}</h2>
            <p className="">
              {ideaData.idea}
            </p>
          </div>

          <div
            className="p-4 grid gap-4"
            style={{
              gridTemplateColumns: "200px 1fr",
              gridTemplateRows: "auto auto",
            }}
          >
            <img
              src={cluster.cluster_data.cluster_img || fallback}
              alt=""
              className="lightbox-main-img col-span-1 row-span-2"
            />

            <div className="col-span-1 space-y-4">
              <div>
                <h3 className="font-bold text-xl">Benefit</h3>
                <p>{ideaData.benefit || "Benefit is not available"}</p>
              </div>
              <div>
                <h3 className="font-bold text-xl">Competitive Analysis</h3>
                <p>{ideaData.competitive_analysis || "Competitive analysis is not available"}</p>
              </div>
            </div>

            <div>
              <p>{ideaData.details}</p>
            </div>
          </div>
        </section>

        {(!ideaData.plan || ideaData.plan.length === 0) ? (
          <p>Idea is currently unavailable</p>
        ) : (
          ideaData.plan.map((planItem, planIndex) => (
            <div className="page-section" key={planIndex}>
              <h3 className="font-bold text-3xl section-header">
                {planItem.title}
              </h3>
              {planItem.steps && (
                <ul className="p-4 text-2xl space-y-3">
                  {planItem.steps.map((bullet, bulletIndex) => (
                    <li
                      className="flex items-baseline gap-x-4"
                      key={bulletIndex}
                    >
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />
                      <span>{bullet}</span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))
        )}

        {ideaData.soundtrack ? (
          <section className="page-section soundtrack">
            <a
              className="flex items-center gap-4 p-4"
              href={ideaData.soundtrack[2]}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex-1">
                <h2 className="text-xl font-bold">{ideaData.soundtrack[0]}</h2>
                <p className="text-lg">{ideaData.soundtrack[1]}</p>
              </div>
              <img
                className="h-16"
                src={youtube}
                alt={ideaData.soundtrack[0]}
              />
            </a>
          </section>
        ) : (
          <p>Soundtrack is currently unavailable</p>
        )}
      </div>
    </div>
  );
}

export default ClusterLightbox;
