import React, { useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import { formatCurrency, formatFloat } from "../../../utils/tableUtils.js";

import './ClusterOverview.css';

export const detailsConfig = {
  resistance: {
    title: 'AI Resistance',
    getContent: (idea) => idea?.resistance_reason ?? 'No resistance reason provided',
  },
  visibility: {
    title: 'AI Visibility',
    getContent: (idea) => idea?.visibility_reason ?? 'No visibility reason provided',
  },
  cost_rationale: {
    title: 'Cost Rationale',
    getContent: (idea) => idea?.cost_rationale ?? 'No cost rationale provided',
  },
};

function ClusterOverview({ cluster }) {
  const [showDetails, setShowDetails] = useState(false);
  const [detailType, setDetailType] = useState(null);

  function showDetail(type) {
    setDetailType(type);
    setShowDetails(true);
  }

  function closeDetail() {
    setShowDetails(false);
    setDetailType(null);
  }

  const { idea = {}, primary_category } = cluster?.cluster_data || {};

    // Grab the correct detail config for the active detail type:
    const currentDetail = detailType ? detailsConfig[detailType] : null;

  return (
    <div className="cluster-overview-container">
      {/* Box 1: Alignment */}
      <div className="cluster-overview-box">
        <h2 className="cluster-overview-heading">
          Alignment
        </h2>

        <div className="h-full flex gap-4">
          {/* category */}
          <div className="cluster-overview-subbox-smallerPadding">
            <h3 className="cluster-overview-label">Category</h3>
            <div className="flex-1 font-normal leading-tight">
              {primary_category ?? '-'}
            </div>
          </div>
        </div>
      </div>

      {/* Box 2: AI Future-Fit (different style) */}
      <div className="cluster-overview-box">
        <h2 className="cluster-overview-heading">AI Future-Fit</h2>

        <div className="h-full flex gap-4">
          {/* resistance */}
          <div
            className={`cluster-overview-subbox-smallerPadding cluster-overview-clickable`}
            onClick={() => showDetail('resistance')}
          >
            <h3 className="cluster-overview-label">Resistance</h3>
            <div className="flex-1 font-normal">
              {idea?.resistance_level ?? '-'}
            </div>
            <FontAwesomeIcon icon={faCircleInfo} className="absolute bottom-1 right-1" size="xs" />
          </div>

          {/* visibility */}
          <div
            className={`cluster-overview-subbox-smallerPadding cluster-overview-clickable`}
            onClick={() => showDetail('visibility')}
          >
            <h3 className="cluster-overview-label">Visibility</h3>
            <div className="flex-1 font-normal">
              {idea?.visibility_factor ?? '-'}
            </div>
            <FontAwesomeIcon icon={faCircleInfo} className="absolute bottom-1 right-1" size="xs" />
          </div>
        </div>
      </div>

      {/* Box 3: Cost Estimates */}
      <div className="cluster-overview-box">
        <h2 className="cluster-overview-heading whitespace-nowrap">
          Cost Estimates
        </h2>

        <div className="h-full flex gap-4">
          {/* build */}
          <div 
            className="cluster-overview-subbox cluster-overview-clickable"
            onClick={() => showDetail('cost_rationale')}
          >
            <h3 className="cluster-overview-label whitespace-nowrap">
              Build
            </h3>
            <div className="flex-1 font-normal">
              { idea?.cost_estimate ? formatCurrency(idea?.cost_estimate, 0) + 'K': '-' }
            </div>
            <FontAwesomeIcon icon={faCircleInfo} className="absolute bottom-1 right-1" size="xs" onClick={() => showDetail('cost_rationale')} />
          </div>

          {/* promotion */}
          <div className="cluster-overview-subbox">
            <h3 className="cluster-overview-label whitespace-nowrap">
              Promotion
            </h3>
            <div className="flex-1 font-normal">
              {formatCurrency(cluster?.cluster_data?.promotion, 0)}
            </div>
          </div>
        </div>
      </div>

      {/* Box 4: Projections */}
      <div className="cluster-overview-box">
        <h2 className="cluster-overview-heading whitespace-nowrap">
          Projections
        </h2>

        <div className="h-full flex gap-4">
          {/* visitors */}
          <div className="cluster-overview-subbox">
            <h3 className="cluster-overview-label whitespace-nowrap">
              Visitors
            </h3>
            <div className="flex-1 font-normal">
              { formatFloat(cluster?.cluster_data?.visitors, 0) ?? '-'}
            </div>
          </div>

          {/* visitor cost */}
          <div className="cluster-overview-subbox">
            <h3 className="cluster-overview-label whitespace-nowrap">
              Visit Cost
            </h3>
            <div className="flex-1 font-normal">
              {formatCurrency(cluster?.cluster_data?.cost_per_visitor, 2)}
            </div>
          </div>
        </div>
      </div>

      {/* rationale overlay */}
      {showDetails && currentDetail && (
        <div
          className="cluster-overview-overlay"
        >
          <button className="cluster-overview-overlay-close" onClick={closeDetail}>
            <FontAwesomeIcon icon={faClose} />
          </button>
          <h2 className="cluster-overview-overlay-heading">
            {currentDetail.title}
          </h2>
          <p className="cluster-overview-overlay-text">
            {currentDetail.getContent(idea)}
          </p>
        </div>
      )}
    </div>
  );
}

export default ClusterOverview;
