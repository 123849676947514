import { Link } from "react-router-dom";
import RefreshButton from "../../../components/RefreshButton/RefreshButton";
import ProjectDifficultyIcon from "../../../components/ProjectList/ProjectDifficultyIcon";
import ProjectStatusIcon from "../../../components/ProjectList/ProjectStatusIcon";

import './ProjectsTable.css';

function ProjectsTable({ projects, loading }) {
  // Handle the empty or null array
  if (!projects || projects.length === 0) {
    return (
      <div className="text-center py-16">
        <h2>No results found</h2>
        <p>Try searching for a different company or brand</p>
      </div>
    );
  }

  return (
    <table
      className={`project-list grid ${loading ? "animate-pulse cursor-wait" : ""}`}
      data-testid="project-list"
    >
      <thead>
        <tr>
          <th className="text-left">
            <h2>Projects</h2>
          </th>
          <th className="text-left flex items-end">Company</th>
          <th className="text-left flex items-end">Condition</th>
          <th className="text-left flex items-end">ACNU</th>
          <th className="text-left flex items-end">Last Run</th>
        </tr>
      </thead>
      <tbody>
        {projects.map((project) => (
          <tr className="project-row" key={project.uuid}>
            <td className="flex gap-2 items-baseline">
              <RefreshButton refresh_property="project" project={project} />
              <Link to={`/project/${project.uuid}`}>{project.project_name}</Link>
            </td>
            <td>
              <Link to={`/company/${project.company_uuid}`}>
                {project.company_name}
              </Link>
            </td>
            <td>{project.condition_analysis_name || "-"}</td>
            <td>
              <ProjectDifficultyIcon acnu_difficulty={project.acnu_difficulty} />
            </td>
            <td className="flex justify-start gap-2">
              <div className="icon-bg">
                <ProjectStatusIcon status={project.processing_status} />
              </div>
              {project.last_run
                ? new Date(project.last_run).toLocaleDateString()
                : "Never"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ProjectsTable;
