import React, { useState } from "react";
import { Link } from "react-router-dom";

import { $axios } from "../../../services/axiosInstance";
import useConditions from "./hooks/useConditions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faChevronRight, faEdit } from "@fortawesome/free-solid-svg-icons";

import ErrorDisplay from "../../../components/ErrorDisplay/ErrorDisplay";
import PaginationV2 from "../../../components/Pagination/PaginationV2";

import spinner from '../../../assets/img/spinner.svg'
import fallback from '../../../assets/img/fallback.webp'

import '../Admin.css';

function ConditionLibrary() {
  const [currentUrl, setCurrentUrl] = useState('/company/condition_analysis/');
  const {
    conditions,
    loading,
    error,
    next,
    previous,
    count,
    totalPages,
  } = useConditions(currentUrl);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshing, setRefreshing] = useState([]);

  const handleRefreshCondition = async (e) => {
    const uuid = e.currentTarget.value;
    setRefreshing([...refreshing, uuid]);

    // /django_api/company/condition_analysis/{uuid}/refresh_condition_with_semrush/
    $axios.put(`/company/condition_analysis/${uuid}/refresh_condition_with_semrush/`)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setRefreshing(refreshing.filter(item => item !== uuid));
      });
  }

  const handleNext = () => {
    if (next) {
      setCurrentUrl(next);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (previous) {
      setCurrentUrl(previous);
      setCurrentPage(currentPage - 1);
    }
  };

  if (error) {
    return (
    <div className="admin-page page-base condition-library">
      <ErrorDisplay error={error} />
    </div>
    )
  }

  return (
    <div className="admin-page page-base condition-library">
    <nav aria-label="breadcrumbs">
      <ol className="flex gap-2 text-xs md:text-sm items-center">
        <li>
          <Link to="/admin">Admin</Link>
          <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
        </li>

        <li>
        <Link to="/admin/library-catalog">Library Catalog</Link>
          <FontAwesomeIcon className='ml-2 text-black dark:text-white' icon={faChevronRight} aria-hidden="true" />
        </li>

        <li className="font-bold text-watermelon-500">
        Condition Library
        </li>
      </ol>
    </nav>

      <section className="page-section">
        <h1 className="section-header">Condition Library</h1>

        <div className="relative flex flex-col min-h-[450px]">
          {loading && (
            <div className="absolute inset-0 bg-black bg-opacity-50 grid place-items-center">
              <img className="h-12 w-12" src={spinner} alt="loading spinner" />
            </div>
          )}
          <ul className="p-2 grid xl:grid-cols-2 gap-2">
            { conditions.map((condition, index) => {
                return (
                  <li className="flex flex-col page-section" key={condition.uuid}>
                    <div className="section-header text-xl space-x-4">
                      <button className="hover:[&>svg]:rotate-45 hover:text-watermelon-500" value={condition.uuid} onClick={handleRefreshCondition} disabled={refreshing.includes(condition.uuid)}>
                        <FontAwesomeIcon className="transition ease-out" icon={faArrowsRotate} spin={refreshing.includes(condition.uuid)} />
                      </button>
                      <h2 className={`inline ${ condition.acronym ? 'uppercase' : ''}`}>
                        <Link key={index} to={`/clusters/${condition.uuid}`}>{condition.name}</Link>
                      </h2>
                      <div className="flex-1 float-right">
                        <Link  to={`/admin/condition-edit/${condition.uuid}`}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </div>
                    </div>

                    <div className="grid" style={{ gridTemplateColumns: '9rem 1fr', gridTemplateRows: '9rem'}}>
                      <Link className="" key={index} to={`/clusters/${condition.uuid}`}>
                        <img src={condition.condition_ai_image1 || fallback} alt={condition.name} className="h-full w-full aspect-square rounded-bl" />
                      </Link>
                      <div className="overflow-hidden p-4">
                      <p
                        style={{
                          display: '-webkit-box',
                          WebkitLineClamp: 5,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >{condition.condition_overview || 'No condition overview available'}</p>
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          { conditions && conditions.length > 0 && <PaginationV2
            count={count}
            next={next}
            previous={previous}
            totalPages={totalPages}
            currentPage={currentPage}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />}
        </div>
      </section>
    </div>
  )
}

export default ConditionLibrary;